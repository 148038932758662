import React, { useState, useEffect } from "react";
import { Home } from "../pages/web/Home";

import { Routes, Route } from "react-router-dom";
import { ClientLayout } from "../layouts/ClientLayout/ClientLayout";
import { map } from "lodash";

export function WebRouter() {
  const [language, setLanguage] = useState("en");

  const loadLayout = (Layout, Page) => {
    return (
      <Layout language={language} setLanguage={setLanguage}>
        <Page language={language} setLanguage={setLanguage} />
      </Layout>
    );
  };
  return (
    <Routes>
      <Route path="/" element={loadLayout(ClientLayout, Home)} />
    </Routes>
  );
}
