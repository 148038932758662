import "./LoginForm.scss";
import React, { useState } from "react";
import { Form, Button, Image } from "semantic-ui-react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getPartnerApi } from "../../api/partners";
import logo from "../../assets/amics_jbb_rmbg.png";
export function LoginForm(props) {
  const { setPartner, partner } = props;

  const [identifierValue, setIdentifierValue] = useState("");
  const [textValue, setTextValue] = useState("");

  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: Yup.object(validationSchema()),
    onSubmit: async (formData) => {
      setPartner("");
      const response = await getPartnerApi(
        formData.partner_number,
        formData.id_numbers
      );
      console.log(response)
      setPartner({
        name: response.name,
        surname: response.surname,
        partner_number: response.partner_number,
      });
      formik.values.id_numbers = "";
      formik.values.partner_number = "";
    },
  });

  return (
    <div className="contact-form-container">
      <ToastContainer />
      <div className="contact-form-container_space"></div>
      <div className="contact-form-container_image">
        <Image src={logo} />
      </div>

      <Form
        className="contact-form-container_texts"
        onSubmit={formik.handleSubmit}
      >
        <Form.Input
          name="partner_number"
          type="text"
          placeholder="Número de soci"
          onChange={formik.handleChange}
          value={formik.values.partner_number}
          error={formik.errors.partner_number}
        />
        <Form.Input
          name="id_numbers"
          type="id_numbers"
          placeholder="Les 4 primeres xifres del DNI"
          onChange={formik.handleChange}
          value={formik.values.id_numbers}
          error={formik.errors.id_numbers}
        />

        <div className="contact-form-container_texts_actions">
          <Button className="submit" type="submit">
            Entrar
          </Button>
        </div>
      </Form>
      <div className="contact-form-container_space"></div>

    </div>
  );
}

function initialValues() {
  return {
    partner_number: "",
    id_numbers: "",
  };
}

function validationSchema() {
  return {
    partner_number: Yup.string().required(true),
    id_numbers: Yup.number().required(true),
  };
}
