import React, { useCallback } from "react";
import { Form, Button, Image, Icon } from "semantic-ui-react";
import { ReactComponent as YouTubeIcon } from "../../assets/img/svg/youtube.svg";
import { ReactComponent as TwitterIcon } from "../../assets/img/svg/twitter.svg";
import { ReactComponent as FacebookIcon } from "../../assets/img/svg/facebook.svg";
import { ReactComponent as LinkedinIcon } from "../../assets/img/svg/linkedin.svg";
import "./SocialLinks.scss";
const facebook = "https://www.facebook.com/AAJBB";
const flickr = "https://www.flickr.com/photos/amicsjbb/";
const instagram = "https://www.instagram.com/amicsdeljardibotanic/";
const twitter = "https://twitter.com/aajbb_info";
const web = "https://www.amicsjbb.org";


export default function SocialLinks() {
  return (
    <div className="socialMedia">
    
      <div className="socialMedia_icons">
    <a href="https://www.facebook.com/AAJBB">  <Icon name='facebook' link size='huge'style={{"color":"#3b5998"}}/></a>

        {/* <Button url={facebook} icon="facebook" style={{"color":"#3b5998"}} size="big" /> */}
      </div>
      <div >
      <a href={instagram}>  <Icon name='instagram' link size='huge'style={{"color":"#F56040"}}/></a>

      </div>
      <div >
      <a href={web}>  <Icon name='world' link size='huge'style={{"color":"#ABD42C"}}/></a>

      </div>
      <div >
      <a href={flickr}>  <Icon name='image' link size='huge'style={{"color":"#a476ec"}}/></a>

      </div>
      <div >
      <a href={twitter}>  <Icon name='twitter' link size='huge'style={{"color":"#1da1f2"}}/></a>

      </div>
    </div>
  );
}
