import "./PartnerCard.scss";
import React, { useState } from "react";
import { Form, Button, Image } from "semantic-ui-react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getPartnerApi } from "../../api/partners";
import logo from "../../assets/amics_jbb_rmbg.png";
import grass from "../../assets/grass-removebg-preview.png";
import SocialLinks from "../SocialLinks/SocialLinks";
export function PartnerCard(props) {
  const { partner, setPartner } = props;
  console.log("partner = ", partner);
  return (
    <div className="partnerCard">
      {partner.name != null ? (
        <>
         <div className="partnerCard_card">
        <div className="partnerCard_card_image">
          <Image src={logo} />
        </div>
        <div className="partnerCard_card_info">
          <p>C/Dr. Font i Quer, 2 - Parc de Montjuïc</p>
          <p>08038-Barcelona</p>
          <p>https://www.amicsjbb.org</p>
          <p>Tel: 932564170</p>
        </div>
        <div className="partnerCard_card_partnerData">
          <h3>
            Nom :{partner.name} {partner.surname}
          </h3>
          <h3>Soci: {partner.partner_number}</h3>
        </div>
        <div className="partnerCard_card_image2">
          <Image src={grass} />
        </div>
      </div>

      <Button
        className="submit"
        type="submit"
        onClick={() => {
          setPartner(null);
        }}
      >
        Sortir
      </Button>

      <div className="partnerCard_card_socialmedia">
        <SocialLinks/>
      </div>
        </>
      ):(
        <>
        <div>No existeix cap usuari amb el número de soci seleccionat</div>

        <Button
        className="submit"
        type="submit"
        onClick={() => {
          setPartner(null);
        }}
      >
        Sortir
      </Button>
        </>
      )}
     
    </div>
  );
}
