import { API_URL } from "../utils/constants.js";

export async function getPartnerApi(partnerNumber, id_numbers) {
  try {
    console.log("id_numbers = ", id_numbers);
    
    const url = `${API_URL}partners/${partnerNumber}`;
    console.log("url = ", url);
    const params = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await fetch(url, params);
    console.log("response = ", response);
    const result = await response.json();
    if (result.id_numbers == id_numbers) {
      return result;
    } else {
      return {"name":null};
    }
  } catch (error) {
    console.log(error);
    return {"name":null};
  }
}
