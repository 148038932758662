import React, { useState, useEffect } from "react";
import "./ClientLayout.scss";

import { BASE_PATH } from "../../utils/constants";

export function ClientLayout(props) {
  return (
    <div className="container">
      <div></div>
      <div>{props.children}</div>
      <div></div>
    </div>
  );
}
