import React, { useState, useEffect } from "react";
import "./Home.scss";
import { LoginForm } from "../../../Components/LoginForm/LoginForm";
import { PartnerCard } from "../../../Components/PartnerCard/PartnerCard";
export function Home() {
  const [partner, setPartner] = useState(null);
  console.log("partner es = ",partner);
  return (
    <div className="HomeContainer">
      {partner ? (
        <PartnerCard partner={partner} setPartner={setPartner} />
      ) : (
        <LoginForm partner={partner} setPartner={setPartner}></LoginForm>
      )}
    </div>
  );
}
